import { ChannelItem } from '@/types'
import { create } from 'zustand'

// Define the store's state
type MeetTheCastState = {
  badge_url: string
  duration: number
  casting: ChannelItem[]
  setBadgeUrl: (value: string) => void
  setCasting: (value: ChannelItem[]) => void
}

// Create the store
export const useMeetTheCastStore = create<MeetTheCastState>((set) => ({
  badge_url: '',
  duration: 3500,
  casting: [],
  setBadgeUrl: (value) => set({ badge_url: value }),
  setCasting: (value) => set({ casting: value }),
}))

import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { ArrowRightLong } from '@/assets/ArrowRightLong'

import { cn } from '@/utils'

const buttonVariants = cva(
  'inline-flex gap-[4px] items-center py-2 tracking-[0.25px] justify-center bg-white/70 hover:bg-white/90 active:cursor-pointer hover:bg-white/90 hover:cursor-pointer focus:bg-white/90 focus:cursor-pointer z-100 font-bold capitalize w-[80%] min-w-[10vw] text-purple text-sm leading-[22px] whitespace-nowrap font-bold ring-offset-white transition-colors focus-visible:outline-none              focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  disabled?: boolean
}

const MoreContentBtn = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp className={cn(buttonVariants({ className }))} ref={ref} {...props}>
        {'more'}
        <ArrowRightLong />
      </Comp>
    )
  },
)
MoreContentBtn.displayName = 'Button'

export { MoreContentBtn, buttonVariants }

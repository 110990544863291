import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../tickets/qrCodeCarousel'

import Barcode from 'react-barcode'
import { Booking } from '@/types'
import { ManageTicket } from './ManageTicket'
import { QRCodeSVG } from 'qrcode.react'
import { TicketButton } from './TicketButton'
import { TicketInfo } from './TicketInfo'
import useScreenSize from '@/hooks/useScreenSize'
import { useState } from 'react'

type TicketCardBackFaceProps = {
  bookingTickets: Booking
  upgradeTicket?: (
    order_id: string,
    instance_uid: string,
    ticketPrice: number,
  ) => void
  handleFlip?: () => void
}

export function TicketCardBackFace({
  bookingTickets,
  upgradeTicket,
  handleFlip,
}: TicketCardBackFaceProps) {
  const screenSize = useScreenSize()
  const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0)
  const [manageTicket, setManageTicket] = useState(false)

  bookingTickets.tickets.sort((a, b) => {
    // Extract row (alphabetical part) and number (numeric part) from seat_name using regular expressions
    const rowA = a.seat_name.replace(/\d+/g, '') // Get the alphabetical part
    const rowB = b.seat_name.replace(/\d+/g, '')

    const numA = parseInt(a.seat_name.match(/\d+/)?.[0] || '0') // Get the numeric part
    const numB = parseInt(b.seat_name.match(/\d+/)?.[0] || '0')

    // First compare rows alphabetically
    const rowComparison = rowA.localeCompare(rowB)
    if (rowComparison !== 0) return rowComparison // If rows are different, return comparison result

    // If rows are the same, compare the seat numbers numerically
    return numA - numB
  })

  return (
    <>
      <div
        className={`${bookingTickets.state.code === 1 && !manageTicket ? `shadow-[0_0_10px_0px_yellow]` : `shadow-[0_0_12px_0px_#CDC3FB]`}
        rounded-[35px] bg-white rounded-[35px] relative flex flex-col items-center
        w-full h-full max-h-[82.5svh] max-w-[85vw] px-[5%]`}
        onClick={() => {
          if (manageTicket) {
            setManageTicket(false)
          } else {
            handleFlip && handleFlip()
          }
        }}
      >
        <div
          className={`w-full h-full ${manageTicket && 'blur-sm opacity-90'}`}
        >
          <div className="h-[72.5%] w-[inherit] flex flex-col justify-center">
            <div className="flex flex-col gap-2 w-full items-center px-2 [&>div]:flex [&>div]:justify-between [&>div]:w-full">
              <div className="w-full items-center flex flex-col">
                <div
                  className={`w-full flex justify-between py-[1.5vh] border-b border-grey2 border-solid ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`section`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {bookingTickets.tickets[carouselCurrentIndex].plan_name}
                  </p>
                </div>

                <div
                  className={`w-full flex justify-between  py-3 border-b border-grey2 border-solid ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`row`}</p>
                  <p className={`text-purple text-sm font-semibold`}>
                    <span className="text-purple text-sm font-semibold">
                      {bookingTickets.tickets[
                        carouselCurrentIndex
                      ].seat_name.replace(/[^a-zA-Z]/g, '')}
                    </span>
                  </p>
                </div>

                <div
                  className={`w-full flex justify-between py-[1.5vh] ${screenSize.width > 380 ? 'max-w-[246px]' : 'max-w-[196px]'}`}
                >
                  <p className="uppercase text-purple font-light text-sm">{`seat`}</p>
                  <p className="text-purple text-sm font-semibold">
                    {bookingTickets.tickets[
                      carouselCurrentIndex
                    ].seat_name.replace(/\D/g, '')}
                  </p>
                </div>
              </div>
              <Carousel
                className={`w-full p-0 ${(bookingTickets.tickets.length < 2 || manageTicket) && 'pointer-events-none'}`}
              >
                <CarouselContent
                  className="m-0"
                  setCarouselCurrentIndex={setCarouselCurrentIndex}
                >
                  {bookingTickets.tickets.map((seat) => {
                    return (
                      <CarouselItem
                        className={`flex flex-col w-full justify-center items-center basis-full pl-0`}
                        key={seat.uid}
                      >
                        <div
                          className={`flex flex-col items-center ${bookingTickets.state.code === 1 && !seat.barcode ? 'gap-1 justify-center pt-2' : 'py-2 w-[100%] h-full'}`}
                        >
                          <div
                            className={`flex items-center ${bookingTickets.state.code === 1 && !seat.barcode ? 'relative rounded-[20px] border-[4px] border-purple3 p-1 mb-1' : ''}`}
                          >
                            <div
                              className={`flex flex-col items-center w-full ${bookingTickets.state.code === 1 && !seat.barcode ? 'blur-[3px]' : ''} [&>svg]:w-full`}
                            >
                              {bookingTickets.config?.is_qr ? (
                                <QRCodeSVG
                                  value={seat.barcode as string}
                                  size={screenSize.width > 380 ? 246 : 196}
                                />
                              ) : (
                                <Barcode
                                  value={seat.barcode as string}
                                  width={3}
                                />
                              )}
                            </div>
                            {bookingTickets.state.code === 1 &&
                              !seat.barcode && (
                                <span className="absolute bg-purple3 py-4 left-[-1px] w-[101%] text-sm">
                                  <h4 className="text-center text-balance px-4">
                                    {`${bookingTickets.config?.is_qr ? 'QR code' : 'Barcode'} will be released before the show`}
                                  </h4>
                                </span>
                              )}
                          </div>
                        </div>
                      </CarouselItem>
                    )
                  })}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="bg-purple6 absolute flex flex-col items-center justify-around w-full h-[27.5%] bottom-0 rounded-[0_0_35px_35px]">
          <div
            className={`w-full ${![0, 4].includes(bookingTickets.state.code) ? 'h-[50%]' : 'h-full'} flex justify-center border-t border-purple2 border-dashed ${manageTicket && 'blur-sm opacity-90'}`}
          >
            <div
              className={`bg-purple w-[28px] h-[28px] rotate-180 rounded-l-full absolute left-[-14px] top-[-15px]`}
            >
              <div
                className={`rounded-l-full w-[14px] h-full absolute left-0 top-0`}
                style={{
                  boxShadow:
                    bookingTickets.state.code === 1
                      ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                      : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
                }}
              ></div>
            </div>
            <div
              className={`bg-purple w-[28px] h-[28px] rounded-l-full absolute right-[-12px] top-[-15px]`}
            >
              <div
                className={`rounded-l-full w-[16px] h-full absolute left-0 top-0`}
                style={{
                  boxShadow:
                    bookingTickets.state.code === 1
                      ? `inset 4px 0 4px rgba(255, 255, 0, 0.4)`
                      : `inset 4px 0 4px rgba(205, 195, 251, 0.4)`,
                }}
              ></div>
            </div>
            <div
              className={`flex flex-col gap-4 h-full items-center ${[0, 4].includes(bookingTickets.state.code) ? 'justify-center' : 'justify-end'} m-0`}
            >
              <TicketInfo
                ticketInfo={bookingTickets}
                upgraded
                embeddedInTicket
              />

              {navigator.onLine &&
                ([0, 4].includes(bookingTickets.state.code) ? (
                  <TicketButton
                    ticketStateCode={bookingTickets.state.code}
                    ticketStateMsg={bookingTickets.state.msg}
                    onClick={() => {
                      if (bookingTickets.state.code === 0) {
                        upgradeTicket &&
                          upgradeTicket(
                            bookingTickets.order_id,
                            bookingTickets.tickets[0].instance_uid,
                            bookingTickets.tickets[0].price,
                          )
                      }
                    }}
                  />
                ) : null)}
            </div>
          </div>
          {navigator.onLine &&
            (bookingTickets.state.code === 1 ||
              bookingTickets.state.code === 3) && (
              <div className="w-full h-[50%] flex items-center justify-center">
                <ManageTicket
                  setManageTicket={setManageTicket}
                  manageTicket={manageTicket}
                />
              </div>
            )}
        </div>
      </div>
    </>
  )
}

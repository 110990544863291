import { motion } from 'framer-motion'
import { useEffect } from 'react'

interface CardFlipperProps {
  FrontFace: React.ReactNode
  BackFace: React.ReactNode
  isCurrentCard?: boolean
  spring?: {
    mass: number
    tension: number
    friction: number
  }
  dx?: any
  dy?: any
  isFlipped?: boolean
  setIsFlipped: (isFlipped: boolean) => void
  isFlippedInactive?: boolean
}

export const CardFlipper = ({
  FrontFace,
  BackFace,
  spring,
  dx,
  dy,
  isFlipped,
  setIsFlipped,
  isCurrentCard,
  isFlippedInactive,
}: CardFlipperProps) => {
  useEffect(() => {
    if (!isCurrentCard) {
      setIsFlipped(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentCard])

  return (
    <div className="w-full h-full relative">
      <motion.div
        transition={spring}
        style={{
          perspective: '1200px',
          transformStyle: 'preserve-3d',
          width: `100%`,
          height: `100%`,
        }}
      >
        <motion.div
          transition={spring}
          style={{
            width: '100%',
            height: '100%',
            rotateX: dx,
            rotateY: dy,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              perspective: '1200px',
              transformStyle: 'preserve-3d',
              width: '100%',
              height: '100%',
              maxWidth: '400px',
              maxHeight: '800px',
            }}
          >
            <motion.div
              animate={{ rotateY: isCurrentCard && isFlipped ? -180 : 0 }}
              transition={spring}
              style={{
                width: '100%',
                height: '100%',
                zIndex: isFlipped ? 0 : 1,
                backfaceVisibility: 'hidden',
                position: 'absolute',
              }}
              className="front left-0 w-full flex items-baseline justify-center px-6"
            >
              {FrontFace}
            </motion.div>
            <motion.div
              initial={{ rotateY: 180 }}
              animate={{ rotateY: isCurrentCard && isFlipped ? 0 : 180 }}
              transition={spring}
              style={{
                width: '100%',
                height: '100%',
                zIndex: isFlipped ? 1 : 0,
                backfaceVisibility: 'hidden',
                position: 'absolute',
              }}
              className="back left-0 w-full flex items-baseline justify-center px-6"
            >
              {BackFace}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  )
}

import { AnimatePresence, motion } from 'framer-motion'
import { ArrowRightLeft, PoundSterling, Send, X } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { MoreCircle } from '@/assets/MoreCircle'
import { transition } from '@/utils/index'
import { useState } from 'react'

interface ManageTicketProps {
  setManageTicket: (value: boolean) => void
  manageTicket: boolean
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...transition,
      delay: 0,
      staggerChildren: 0.2,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.1,
      staggerChildren: 0.1,
      when: 'afterChildren',
    },
  },
}

const childVariants = {
  hidden: { opacity: 0, y: -20, X: 0 },
  visible: (index: number) => {
    const yOffset = '-10vh'
    const xOffset = index === 0 ? -7.5 : index === 2 ? 7.5 : 0
    return {
      opacity: 1,
      y: yOffset,
      x: xOffset,
    }
  },
  exit: (index: number) => {
    const yExit = -20
    const xExit = index === 0 ? 30 : index === 2 ? -30 : 0
    return {
      opacity: 0,
      y: yExit,
      x: xExit,
    }
  },
}

export const ManageTicket = ({
  setManageTicket,
  manageTicket,
}: ManageTicketProps) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const items = [
    { text: 'swap', icon: <ArrowRightLeft size={16} /> },
    { text: 'send', icon: <Send size={16} /> },
    { text: 'refund', icon: <PoundSterling size={16} /> },
  ]

  return (
    <>
      <AnimatePresence>
        {manageTicket && (
          <motion.div
            className="absolute w-full px-[5vw] flex justify-center items-center text-yellow capitalize"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            {items.map((item, index) => (
              <motion.div
                key={item.text}
                className="w-full"
                variants={childVariants}
                custom={index}
              >
                <span className="w-full flex gap-1 justify-center items-center bg-purple py-3 rounded-full [&>svg]:relative [&>svg]:bottom-[1.5px]">
                  {item.text}
                  {item.icon}
                </span>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <Button
        variant="textOnly"
        size="small"
        className={`${isAnimating && 'transition-opacity ease opacity-70 pointer-events-none'} absolute border font-bold text-purple border-purple border-2 z-[1000]`}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation()
          if (!isAnimating) {
            setManageTicket(!manageTicket)
          }
        }}
      >
        {`Manage`}
        <MoreCircle />
      </Button>
    </>
  )
}

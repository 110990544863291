export const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
    >
      <path
        d="M8.00106 0.681049L8.00106 0.681047C8.24347 0.438635 8.63638 0.438738 8.87852 0.680976L8.87859 0.681047C9.12071 0.923163 9.12087 1.31598 8.87861 1.55847C8.87857 1.5585 8.87854 1.55853 8.87851 1.55856L2.79106 7.64566L2.43748 7.99922L2.79105 8.35278L8.87831 14.4399C8.87834 14.44 8.87836 14.44 8.87839 14.44C9.12065 14.6825 9.1205 15.0752 8.8784 15.3173L8.87823 15.3175C8.6361 15.5598 8.24333 15.5599 8.00093 15.3174L8.00084 15.3173L1.12128 8.4379C1.12123 8.43786 1.12118 8.43781 1.12113 8.43776C1.0002 8.31665 0.939792 8.1588 0.939792 7.99922C0.939792 7.83976 1.0003 7.68184 1.12135 7.56069C1.12138 7.56066 1.12141 7.56063 1.12144 7.5606L8.00106 0.681049Z"
        fill="#ffffff"
        stroke="#ffffff"
      />
    </svg>
  )
}

import { useEffect, useRef, useState } from 'react'

import { ExpandableText } from './ExpandableText'
import { SeekBar } from './SeekBar'
import { TicketsIconYellow } from '@/assets/TicketsIconYellow'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useNavigate } from 'react-router-dom'

interface MainTileProps {
  childVariants: any
  badge_url: string
  video_url: string
  video_description: string
  name: string
  isExclusive: boolean
}

export const MainTile = ({
  childVariants,
  badge_url,
  video_url,
  video_description,
  name,
  isExclusive,
}: MainTileProps) => {
  const { setMainContent } = useFullScreenContentStore()
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [isMuted, setIsMuted] = useState(true)

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
    }
  }, [loaded])

  // Update currentTime as the video plays
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  return (
    <motion.div variants={childVariants} className="relative m-0 w-full">
      {!loaded && (
        <div
          className={`h-[66vh] bg-purple1 pb-1 flex justify-center items-center [&>svg]:relative [&>svg]:bottom-4 [&>svg]:animate-pulse`}
        >
          <TicketsIconYellow />
        </div>
      )}

      <video
        ref={videoRef}
        autoPlay
        loop
        controls={false}
        muted={isMuted}
        playsInline={true}
        className={`w-full object-cover h-[66vh] ${!loaded && 'hidden'}`}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onClick={() => {
          setMainContent({ badge_url, video_url, video_description, name })

          navigate(`/fullscreen/313c3231-70e4-4c09-8986-32ef6f3bc3af`)
        }}
      >
        <source src={video_url} />
      </video>

      {isExclusive && (
        <div className="w-full flex justify-center absolute top-0">
          <span className="relative h-fit top-0 right-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">{`Exclusive`}</span>
        </div>
      )}

      <div className="w-full items-center justify-between absolute py-4 p-4 bottom-0 flex bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]">
        <div className="flex flex-col w-full gap-2">
          <div className="flex gap-2 items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <img
                src={badge_url}
                alt="preshowOne"
                className="w-8 h-8 rounded-[4px]"
              />
              <p className="font-bold">{name}</p>
            </div>
            <div className="relative">
              <div
                className="cursor-pointer top-0 z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                onClick={() => setIsMuted(!isMuted)}
              >
                {isMuted ? <VolumeOff /> : <VolumeOn />}
              </div>
            </div>
          </div>

          {video_description && video_description.length && (
            <ExpandableText text={video_description} limit={150} />
          )}

          {loaded && (
            <div className="relative flex flex-col items-center bg-transparent py-2">
              <SeekBar
                videoRef={videoRef}
                currentIndex={0}
                duration={duration}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                ref={videoRef}
                isActive
              />

              {/* Custom styles to hide the default thumb */}
              <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

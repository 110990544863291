import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { ExpandableText } from '../../components/upseat-ui/contentChannel/ExpandableText'
import { HouseIcon } from '@/assets/HouseIcon'
import NavLayout from '@/layouts/NavLayout'
import { SeekBar } from '../../components/upseat-ui/contentChannel/SeekBar'
import { motion } from 'framer-motion'
import { transitionOut } from '@/utils'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'

export const MeetTheCast = ({ feed }: { feed?: boolean }) => {
  const { duration, badge_url, casting } = useMeetTheCastStore()
  const { fullScreenIndex, setFullScreenIndex } = useFullScreenContentStore()
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const isFeed =
    new URLSearchParams(useLocation().search).get('feed') === 'true'
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const [isExclusive, setIsExclusive] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)
  const [seekBarsProgress, setSeekBarsProgress] = useState<number[]>(
    Array(casting[0].dynamic.length).fill(0),
  )
  const imageRef = useRef<HTMLImageElement>(null)
  const isSeekBarCompleteRef = useRef<boolean[]>(
    Array(casting[0].dynamic.length).fill(false),
  )

  const animationFrameId = useRef<number | null>(null)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: { opacity: 0, transition: transitionOut },
  }

  useEffect(() => {
    if (swipeDirection && isFeed) {
      if (swipeDirection === 'up') {
        setFullScreenIndex(fullScreenIndex + 1)
      } else {
        setFullScreenIndex(0)
      }

      navigate(`/fullscreen/313c3231-70e4-4c09-8986-32ef6f3bc3af`)
    }

    resetSwipeDirection()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  useEffect(() => {
    setSeekBarsProgress((prevProgress) =>
      prevProgress.map((progress, index) =>
        index === currentIndex ? 0 : progress,
      ),
    )

    if (isExpanded) return
    const updateProgress = () => {
      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (index < currentIndex) {
            // Set progress to max for all completed items
            return duration / 1000
          } else if (index === currentIndex && !isExpanded) {
            // Update current item's progress
            const newProgress = Math.min(progress + 1 / 60, duration / 1000)
            isSeekBarCompleteRef.current[currentIndex] =
              newProgress >= duration / 1000

            return newProgress
          }
          return progress // Retain the progress for future items
        }),
      )

      if (!isExpanded) {
        animationFrameId.current = requestAnimationFrame(updateProgress)
      }
    }

    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current)
    }
    animationFrameId.current = requestAnimationFrame(updateProgress)

    return () => cancelAnimationFrame(animationFrameId.current as number)
  }, [duration, currentIndex, isExpanded])

  const handleSkipContent = (next?: boolean) => {
    if (!next && currentIndex === 0) return
    setIsExpanded(false)

    setCurrentIndex((prevIndex) => {
      setSeekBarsProgress((prevProgress) =>
        prevProgress.map((progress, index) => {
          if (next) {
            // Forward: fill the progress of items before the current index
            if (index === casting[0].dynamic.length - 1) {
              setSeekBarsProgress((prevProgress) =>
                prevProgress.map((progress, index) => {
                  return 0 // Reset all items when reaching the end
                }),
              )
            } else if (index < prevIndex) {
              return duration / 1000 // Set as completed
            } else if (index === prevIndex) {
              return 0 // Reset current item when moving forward
            }
          } else {
            // Backward: reset the current item and keep previous items full
            if (index === prevIndex) {
              return 0 // Reset current item when moving backward
            } else if (index < prevIndex) {
              return duration / 1000 // Keep previous items completed
            }
          }
          return progress
        }),
      )

      // Adjust index based on direction
      if (next) {
        return (prevIndex + 1) % casting[0].dynamic.length
      } else {
        return prevIndex === 0 ? casting[0].dynamic.length - 1 : prevIndex - 1
      }
    })
  }

  return (
    <NavLayout>
      <motion.section
        id="pre-show-content-container"
        className={`flex flex-col justify-start items-center w-full h-[100svh]`}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
      >
        <motion.span
          onClick={() => {
            navigate(`/tickets`)
          }}
          className="fixed cursor-pointer top-0 right-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
        >
          <HouseIcon />
        </motion.span>

        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => {
            setFullScreenIndex(0)
            navigate(`/channel/313c3231-70e4-4c09-8986-32ef6f3bc3af`)
          }}
          className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
        >
          <Arrow />
        </motion.span>

        <motion.div className="relative m-0 w-full h-full min-h-[100svh] flex flex-col justify-center">
          <div className="relative w-full h-full">
            {isExclusive && (
              <div className="w-full h-full flex justify-center">
                <span className="absolute top-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">{`Exclusive`}</span>
              </div>
            )}

            <div className="absolute top-0 z-10 w-full h-full flex">
              <div
                className="w-[50%] h-[100svh]"
                onClick={() => handleSkipContent()}
              ></div>
              <div
                className="w-[50%] h-[100svh]"
                onClick={() => handleSkipContent(true)}
              ></div>
            </div>

            {!errorLoadingImage && (
              <img
                src={casting[0].dynamic[currentIndex].content_url}
                alt={casting[0].dynamic[currentIndex].content_url}
                className={`w-full h-[100svh] object-cover absolute top-0`}
                onError={() => setErrorLoadingImage(true)}
              />
            )}

            {errorLoadingImage && (
              <div className="w-full h-[100svh]">
                <p className="absolute inset-0 z-20 text-white font-headline text-center flex items-center justify-center">
                  {`Error loading image`}
                </p>
              </div>
            )}

            <div className="absolute inset-0 z-20 pointer-events-none" />
          </div>

          <div className="w-full items-center justify-between absolute py-4 p-4 bottom-0 flex z-50 bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]">
            <div className="flex flex-col w-full">
              <div className="w-full flex flex-col gap-4 items-center justify-start">
                <div className="w-full flex gap-2 items-center">
                  <img
                    src={badge_url}
                    alt={badge_url}
                    className="w-8 h-8 rounded-[4px]"
                  />
                  <p className="text-sm font-bold">
                    {casting[0].dynamic[currentIndex].name}
                  </p>
                </div>

                <p className="w-full text-sm font-bold">
                  {`Character: `}
                  <span className="font-normal">
                    {casting[0].dynamic[currentIndex].character}
                  </span>
                </p>

                <ExpandableText
                  text={casting[0].dynamic[currentIndex].description}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                  limit={150}
                />
              </div>

              <div className="relative flex flex-col items-center bg-transparent pb-4 pt-6">
                <div className="w-full flex items-center gap-2">
                  {casting[0].dynamic.map((cast: any, index: number) => (
                    <SeekBar
                      key={index}
                      currentIndex={currentIndex}
                      duration={duration / 1000}
                      currentTime={seekBarsProgress[index]}
                      setCurrentTime={setCurrentIndex}
                      isActive={index === currentIndex}
                    />
                  ))}
                  <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.section>
    </NavLayout>
  )
}

import { TicketsIconYellow } from '@/assets/TicketsIconYellow'

type TicketImageProps = {
  src: string
  loaded: boolean
  setLoaded: (loaded: boolean) => void
  handleFlip?: () => void
}

export default function TicketImage({
  src,
  loaded,
  setLoaded,
  handleFlip,
}: TicketImageProps) {
  return (
    <div onClick={handleFlip && handleFlip} className="relative w-full h-full">
      {!loaded && (
        <div
          className={`z-30 w-full h-[82.5svh] z-10 rounded-[35px] bg-purple flex justify-center items-center [&>svg]:relative [&>svg]:bottom-20 [&>svg]:animate-pulse`}
        >
          <TicketsIconYellow />
        </div>
      )}
      <img
        src={src}
        alt="ticket"
        className={`w-full h-full object-cover transition-opacity duration-500 ${
          loaded ? 'opacity-100' : 'opacity-0'
        }`}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}

import { ChannelItem } from '@/types'
import { create } from 'zustand'

interface MainContent {
  name: string
  video_description: string
  badge_url: string
  video_url: string
}

// Define the store's state
type FullScreenContentState = {
  fullScreenIndex: number
  mainContent: MainContent
  fullScreenChannel: ChannelItem[]
  currentEvent: ChannelItem | null
  isMeetTheCast: boolean
  setIsMeetTheCast: (value: boolean) => void
  setFullScreenIndex: (value: number) => void
  setMainContent: (value: MainContent) => void
  skipToNextContent: () => void
  skipToPrevContent: () => void
  setEventInfo: (value: ChannelItem) => void
  setFullScreenChannel: (value: ChannelItem[]) => void
  setCurrentEvent: (value: ChannelItem) => void
}

// Create the store
export const useFullScreenContentStore = create<FullScreenContentState>(
  (set) => ({
    fullScreenIndex: 0,
    mainContent: {
      name: '',
      video_description: '',
      badge_url: '',
      video_url: '',
    },
    fullScreenChannel: [], // initial state
    currentEvent: null,
    isMeetTheCast: false,
    setIsMeetTheCast: (value) => set({ isMeetTheCast: value }),
    setFullScreenIndex: (value) => set({ fullScreenIndex: value }),
    setMainContent: (value) => set({ mainContent: value }),
    skipToNextContent: () => {},
    skipToPrevContent: () => {},
    setEventInfo: (value) => set({ currentEvent: value }),
    setFullScreenChannel: (value) => set({ fullScreenChannel: value }),
    setCurrentEvent: (value) => set({ currentEvent: value }),
  }),
)

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M9.47 4.38386C9.01358 4.1273 8.49796 3.99466 7.9744 3.99913C7.45083 4.0036 6.93755 4.14502 6.48558 4.40933C6.0336 4.67364 5.65867 5.05163 5.39805 5.50575C5.13744 5.95986 5.0002 6.47427 5 6.99786V24.9979C4.99985 25.5216 5.13683 26.0363 5.39731 26.4907C5.65778 26.9451 6.03268 27.3233 6.48471 27.5879C6.93675 27.8524 7.45016 27.994 7.9739 27.9986C8.49763 28.0031 9.01343 27.8705 9.47 27.6139L25.47 18.6139C25.9341 18.3529 26.3204 17.9732 26.5892 17.5136C26.858 17.0541 26.9997 16.5313 26.9997 15.9989C26.9997 15.4664 26.858 14.9436 26.5892 14.4841C26.3204 14.0245 25.9341 13.6448 25.47 13.3839L9.47 4.38386Z"
      fill="white"
    />
  </svg>
)

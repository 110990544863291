import { ChannelItem, ContentType } from '@/types'
import { useEffect, useRef, useState } from 'react'

import { GridImage } from './GridImage'
import { GridVideo } from './GridVideo'
import { TicketsIconYellow } from '@/assets/TicketsIconYellow'
import { isVideo } from '@/utils'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useNavigate } from 'react-router-dom'

interface GridProps {
  channelContent: ChannelItem[]
  childVariants: any
  badge_url: string
  video_url: string
  video_description: string
  name: string
}

export const GridTile = ({
  channelContent,
  badge_url,
  video_url,
  video_description,
  name,
  childVariants,
}: GridProps) => {
  const navigate = useNavigate()
  const { fullScreenChannel, setFullScreenIndex, setMainContent } =
    useFullScreenContentStore()
  const [loaded, setLoaded] = useState(false)
  const [videoResolution, setVideoResolution] = useState({
    width: 0,
    height: 0,
  })

  const imageRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const ref = useRef(null)

  useEffect(() => {
    const videoElement = videoRef.current
    const imageElement = imageRef.current

    const handleLoadedMetadata = () => {
      if (videoElement) {
        const width = videoElement.videoWidth
        const height = videoElement.videoHeight
        setVideoResolution({ width, height })
      }
    }

    if (videoElement)
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    if (imageElement)
      imageElement.addEventListener('load', handleLoadedMetadata)

    return () => {
      if (videoElement)
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      if (imageElement)
        imageElement.removeEventListener('load', handleLoadedMetadata)
    }
  }, [])

  const sortedChannelContent = channelContent.sort(
    (a: ChannelItem, b: ChannelItem) => a.y_axis - b.y_axis,
  )

  return (
    <motion.div
      variants={childVariants}
      ref={ref}
      className="flex flex-wrap h-full overflow-hidden bg-purple"
    >
      {sortedChannelContent.map((channelItem, i) => {
        if (isVideo(channelItem.content_url)) {
          return (
            <motion.div
              key={channelContent[i].content_url}
              className="relative flex flex-col pt-1"
            >
              <motion.div>
                <GridVideo
                  url={channelItem.content_url}
                  description={channelItem.description as string}
                  videoResolution={videoResolution}
                  loaded={loaded}
                  setLoaded={setLoaded}
                  setCurrentEvent={() => {
                    setFullScreenIndex(
                      fullScreenChannel.findIndex(
                        (c) => channelItem.content_url === c.content_url,
                      ),
                    )
                    setMainContent({
                      badge_url,
                      video_url,
                      video_description,
                      name,
                    })
                    navigate(`/fullscreen/313c3231-70e4-4c09-8986-32ef6f3bc3af`)
                  }}
                />
                {!loaded && (
                  <div className="z-30 w-full h-[40vh] bg-purple1 flex justify-center items-center [&>svg]:animate-pulse [&>svg]:w-12">
                    <TicketsIconYellow />
                  </div>
                )}
              </motion.div>
            </motion.div>
          )
        } else {
          return (
            <motion.div
              className={`w-[50%] pt-1 ${channelItem.x_axis === 0 && 'pr-[.125rem]'} ${channelItem.x_axis === 1 && 'pl-[.125rem]'}`}
              key={channelItem.content_url}
            >
              <GridImage
                url={channelItem.content_url}
                alt={channelItem.description ?? channelItem.content_url}
                btnText={
                  channelItem.content_type === ContentType.ACTOR
                    ? channelItem.description
                    : channelItem.content_type === ContentType.EXTERNAL
                      ? channelItem.dynamic.button_content
                      : ''
                }
                externalLink={
                  channelItem.content_type === ContentType.EXTERNAL
                    ? channelItem.dynamic.external_link
                    : ''
                }
                loaded={loaded}
                setLoaded={setLoaded}
              />
            </motion.div>
          )
        }
      })}
    </motion.div>
  )
}

export type User = {
  id: string
  username?: string
  firstname?: string
  lastname?: string
  email: string
  countryCode?: CountryCode
  phoneNumber?: string
}

export type Location = {
  id: string
  name: string
}

export type Currency = {
  name: string
  symbol: string
}

export type Seat = {
  seat_id: string
  location: string
  name: string
  row: string
  number: string
  band_uid: string
  tier: number
  upgrade_price: number
  original_price: number
  discount: number
}

export type PriceBand = {
  uid: string
  active_discount: number
  original_price: number
  discount_price?: number
}

export type CountryCode = {
  code: string
  acronym: string
  digits: string
}

export enum TicketStates {
  UPGRADE_NOW = 'Upgrade Now',
  UPSEATED = 'Upgraded',
  UPGRADE_TO_BE_AVAILABLE = 'Upgrades Available Soon',
  VIEW_TICKET = 'View Ticket',
}

export type State = {
  code: number
  msg: string
}

export type Config = {
  uid: string
  is_qr: boolean
  payment_enabled: boolean
  basket_enabled: boolean
}

export const TicketStateMap: Record<number, TicketStates> = {
  0: TicketStates.UPGRADE_NOW,
  1: TicketStates.UPSEATED,
  2: TicketStates.VIEW_TICKET,
  3: TicketStates.VIEW_TICKET,
  4: TicketStates.UPGRADE_TO_BE_AVAILABLE,
  5: TicketStates.VIEW_TICKET,
  6: TicketStates.VIEW_TICKET,
}

export type Booking = {
  state: State
  config: Config
  order_id: string
  uid: string
  customer_id: string
  total_price: number
  tickets: Ticket[]
}

export type Ticket = {
  uid: string
  instance_uid: string
  event_name: string
  thumbnail_url: string
  plan_name: string
  seat_name: string
  start_time: Date | string
  duration: number
  price: number
  barcode: string | null
  theatre_name: string
}

export type ShowData = {
  title: string
  dateFrom: Date
  dateTo: Date
  imageUrl?: string
}

export enum ShowErrorStatus {
  NOT_AVAILABLE = 'not available',
  SOLD_OUT = 'sold out',
  NO_PARTNERSHIP = 'no partnership',
}

export type PricePoint = {
  percentage: number
  seatType: string
}

export type BasketSeat = {
  seat_id: string
  band: {
    uid: string
    active_discount: number
    original_price: number
    discount_price: number
  }
}

export type Basket = {
  uid: string
  instance_uid: string
  upgrade_uid: string
  total_discount: number
  total_price: number
  payment_confirmed: boolean
  seats: BasketSeat[]
  expires_utc: Date
}

export type UpBooking = {
  order_id: string
  barcode: string
  duration: number
  event_name: string
  instance_uid: string
  plan_name: string
  price: number
  seat_name: string
  start_time: Date
  theatre_name: string
  thumbnail_url: string
  uid: string
  invalidated: boolean
  upseated: boolean
  tickets: Ticket[]
}

export enum SectionNames {
  HOME = 'HOME',
  SEARCH = 'SEARCH',
  TICKETS = 'TICKETS',
  ACCOUNT = 'ACCOUNT',
}

export type Section = {
  index: number
  name: SectionNames
}

export const sectionMap: Record<number, SectionNames> = {
  0: SectionNames.HOME,
  1: SectionNames.SEARCH,
  2: SectionNames.TICKETS,
  3: SectionNames.ACCOUNT,
}

export interface SeatMapTestProps {
  selectedSeats: Seat[]
  setSelectedSeats: (seats: Seat[]) => void
  priceBands: PriceBand[]
  mapSeats: Seat[]
}

export enum ContentType {
  STATIC = 'STATIC',
  ACTOR = 'ACTOR',
  EXTERNAL = 'EXTERNAL',
}

export interface Dynamic {
  content_length: number
  content_url: string
  description: React.ReactNode
  name: string
}

export interface ChannelItem {
  content_type: ContentType
  content_url: string
  description: string | null
  dynamic: any
  x_axis: number
  y_axis: number
}

export interface Channel {
  uid: string
  name: string
  poster_url: string
  badge_url: string
  description: string
  channel: ChannelItem[] | boolean[]
}

export const VolumeOn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.26 3.20534C16.4165 3.09339 16.5997 3.02444 16.7911 3.0054C16.9826 2.98636 17.1758 3.01788 17.3512 3.09682C17.5267 3.17576 17.6785 3.29936 17.7912 3.45527C17.904 3.61119 17.9739 3.79396 17.994 3.98534L18 4.09934V19.7113C18.0001 19.9038 17.9496 20.0929 17.8538 20.2597C17.7579 20.4266 17.6199 20.5654 17.4536 20.6622C17.2874 20.7591 17.0986 20.8106 16.9061 20.8117C16.7137 20.8128 16.5244 20.7633 16.357 20.6683L16.261 20.6063L9.68 15.9053H7C6.49542 15.9055 6.00943 15.7149 5.63945 15.3718C5.26947 15.0288 5.04284 14.5585 5.005 14.0553L5 13.9053V9.90534C5.00009 9.40094 5.19077 8.9152 5.53384 8.54543C5.8769 8.17566 6.34702 7.94917 6.85 7.91134L7 7.90534H9.68L16.26 3.20534Z"
        fill="white"
      />
    </svg>
  )
}

interface ExpandableTextProps {
  text: string
  limit: number
  isExpanded?: boolean
  setIsExpanded?: (expandText: boolean) => void
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  limit,
  isExpanded,
  setIsExpanded,
}) => {
  const toggleExpand = () => setIsExpanded && setIsExpanded(!isExpanded)

  const castInfo = text.toString()
  const castInfoReduced =
    castInfo.slice(0, limit) +
    (text.toString() && text.toString().length > limit ? ' ...' : '')

  return (
    <div className="w-full flex justify-between items-end text-sm">
      <p
        dangerouslySetInnerHTML={{
          __html: isExpanded ? castInfo : castInfoReduced,
        }}
      ></p>
      {text.toString() && text.toString().length > limit && (
        <button onClick={toggleExpand} className="font-bold">
          {isExpanded ? '...less' : '...more'}
        </button>
      )}
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { ExpandableText } from './ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'

interface GridVideoProps {
  url: string
  description: string
  loaded: boolean
  videoResolution: {
    width: number
    height: number
  }
  setLoaded: (loaded: boolean) => void
  setCurrentEvent: (contentUrl: string) => void
}

export const GridVideo: React.FC<GridVideoProps> = ({
  url,
  description,
  loaded,
  videoResolution,
  setLoaded,
  setCurrentEvent,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.controls = false
    }
  }, [loaded])

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play()
        setIsMuted(false)
      } else {
        videoRef.current.pause()
      }
    }
  }, [isPlaying])

  // Update currentTime as the video plays
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  // Set video duration when metadata is loaded
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
      setLoaded(true)
    }
  }

  return (
    <div className="relative w-full h-full flex justify-center items-center">
      {/* Video Element */}
      <video
        ref={videoRef}
        muted={isMuted}
        autoPlay
        playsInline
        className={`w-[100vw] h-full max-h-[50vh] object-cover ${!loaded && 'hidden'} ${
          videoResolution?.height < videoResolution?.width && `max-h-[20vh]`
        }`}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onClick={() => setCurrentEvent(url)}
      >
        <source src={url} />
      </video>
      {!isPlaying && loaded && (
        <div className="absolute">
          <div
            className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <PlayIcon />
          </div>
        </div>
      )}
      <div
        className={`absolute bottom-0 flex w-full items-center justify-between p-4 ${description && 'bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]'}`}
      >
        {description && (
          <div className="pr-4">
            <ExpandableText text={description} limit={150} />
          </div>
        )}
        {isPlaying && (
          <div className={`${!description && 'absolute right-0 bottom-0 p-4'}`}>
            <div
              className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
              onClick={() => setIsMuted(!isMuted)}
            >
              {isMuted ? <VolumeOff /> : <VolumeOn />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'

type SwipeDirection = 'up' | 'down' | null

export const useSwipeDirection = () => {
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null)
  const [touchStartY, setTouchStartY] = useState<number | null>(null)

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setTouchStartY(e.touches[0].clientY) // Capture the Y-coordinate
    }

    const handleTouchMove = (e: TouchEvent) => {
      if (touchStartY === null) return

      const touchEndY = e.touches[0].clientY
      const deltaY = touchStartY - touchEndY

      // Threshold to detect a swipe (you can adjust this)
      const swipeThreshold = 150

      if (Math.abs(deltaY) > swipeThreshold) {
        setSwipeDirection(deltaY > 0 ? 'up' : 'down')
        setTouchStartY(null) // Reset start position after detecting swipe
      }
    }

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)

    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [touchStartY])

  const resetSwipeDirection = () => setSwipeDirection(null)

  return { swipeDirection, resetSwipeDirection }
}

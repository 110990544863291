import { ArrowRightLong } from '@/assets/ArrowRightLong'
import { Button } from '@/components/ui/button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface GridImageProps {
  url: string
  alt: string
  btnText: string
  externalLink: string
  loaded: boolean
  setLoaded: (loaded: boolean) => void
}

export const GridImage: React.FC<GridImageProps> = ({
  url,
  alt,
  btnText,
  externalLink,
  loaded,
  setLoaded,
}) => {
  const navigate = useNavigate()
  return (
    <div
      className={`w-full h-full relative flex flex-col justify-between items-center ${!loaded && 'hidden'}`}
    >
      <img
        src={url}
        alt={alt}
        onLoad={() => setLoaded(true)}
        className={`border-none h-[20vh] w-full`}
      />
      <span
        className={`absolute w-full h-[20vh] ${btnText && 'bg-purple/70'}`}
      ></span>
      <div
        className={`${btnText && 'absolute'} ${!loaded || (!btnText && 'hidden')} w-full h-full flex items-center justify-center`}
      >
        <Button
          variant="primaryYellow"
          size="default"
          className="text-sm h-[20%] w-[90%]"
          onClick={() => {
            if (externalLink) {
              window.open(externalLink, '_blank')
            } else {
              navigate('/meet-the-cast/313c3231-70e4-4c09-8986-32ef6f3bc3af')
            }
          }}
        >
          {externalLink && (
            <span className="rotate-180">
              <ArrowRightLong />
            </span>
          )}
          {btnText}
          {!externalLink && <ArrowRightLong />}
        </Button>
      </div>
    </div>
  )
}

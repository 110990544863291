import { Channel, ChannelItem, ContentType } from '@/types'
import { isVideo, transitionOut } from '@/utils'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Arrow } from '@/assets/Arrow'
import { GridTile } from '@/components/upseat-ui/contentChannel/GridTile'
import { MainTile } from '@/components/upseat-ui/contentChannel/MainTile'
import NavLayout from '@/layouts/NavLayout'
import UpLogo from '@/assets/upseat_logo.svg'
import { getPreShow } from '@/service/PreShowEngagement/getPreShow'
import { motion } from 'framer-motion'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import useScreenSize from '@/hooks/useScreenSize'

export const ContentChannel = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const screen = useScreenSize()
  const { setCasting, setBadgeUrl } = useMeetTheCastStore()
  const { setFullScreenChannel } = useFullScreenContentStore()
  const [isExclusive, setIsExclusive] = useState(true)
  const [channelContent, setChannelContent] = useState<Channel>({
    uid: '',
    name: '',
    description: '',
    badge_url: '',
    poster_url: '',
    channel: [],
  })

  useEffect(() => {
    if (!id) {
      navigate('/tickets')
    } else {
      getPreShow(id).then((res) => {
        setChannelContent(res.data)
        setCasting(
          res.data.channel.filter(
            (cont: ChannelItem) => cont.content_type === ContentType.ACTOR,
          ),
        )
        setBadgeUrl(res.data.badge_url)
        const fullScreenContent = res.data.channel.filter(
          (cont: ChannelItem) =>
            cont.content_type === ContentType.STATIC &&
            isVideo(cont.content_url),
        )

        fullScreenContent.splice(1, 0, false)
        setFullScreenChannel(fullScreenContent)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: { opacity: 0, transition: transitionOut },
  }

  const childVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <NavLayout>
      <motion.section
        id="pre-show-content-container"
        className={`flex flex-col justify-start items-center h-full w-full min-h-[100svh]`}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
      >
        {useConditionalRender(
          !!channelContent,
          <>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.3 }}
              onClick={() => navigate(`/tickets`)}
              className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              <Arrow />
            </motion.span>
          </>,
        )}

        {channelContent.channel.length ? (
          <main className="w-full">
            <MainTile
              childVariants={childVariants}
              badge_url={channelContent.badge_url}
              video_url={channelContent.poster_url}
              video_description={channelContent.description}
              name={channelContent.name}
              isExclusive={isExclusive}
            />
            <GridTile
              channelContent={channelContent.channel as ChannelItem[]}
              childVariants={childVariants}
              badge_url={channelContent.badge_url}
              video_url={channelContent.poster_url}
              video_description={channelContent.description}
              name={channelContent.name}
            />
            <footer className="w-full bg-purple flex justify-center items-center">
              <img
                src={UpLogo}
                alt="up-logo"
                className={`w-8 py-12`}
                onClick={() => navigate('/tickets')}
              />
            </footer>
          </main>
        ) : (
          <div className="w-full h-[100svh] flex justify-center items-center">
            <img
              src={UpLogo}
              alt="up-logo"
              className={`${screen.width > 1024 ? 'w-[10vw]' : 'w-[20vw]'}`}
            />
          </div>
        )}
      </motion.section>
    </NavLayout>
  )
}

import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Arrow } from '@/assets/Arrow'
import { ExpandableText } from '@/components/upseat-ui/contentChannel/ExpandableText'
import { HouseIcon } from '@/assets/HouseIcon'
import { PlayIcon } from '@/assets/PlayIcon'
import { SeekBar } from '@/components/upseat-ui/contentChannel/SeekBar'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useNavigate } from 'react-router-dom'
import { useSwipeDirection } from '@/hooks/useSwipeDirection'

export const FullScreen = () => {
  const {
    mainContent,
    fullScreenChannel,
    currentEvent,
    fullScreenIndex,
    setCurrentEvent,
    setFullScreenIndex,
  } = useFullScreenContentStore()
  const { swipeDirection, resetSwipeDirection } = useSwipeDirection()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [duration, setDuration] = useState(0)
  const [isExclusive, setIsExclusive] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [contentLoaded, setContentLoaded] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setCurrentEvent(fullScreenChannel[fullScreenIndex])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFullScreenIndex])

  useEffect(() => {
    if (videoRef.current) {
      setContentLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (videoRef.current && isPlaying) {
      setIsMuted(false)
      videoRef.current.play().catch(() => {
        setIsPlaying(false)
      })
    } else {
      videoRef.current?.pause()
    }
  }, [isPlaying])

  useEffect(() => {
    if (swipeDirection) {
      handleSkipContent(swipeDirection === 'up')
      resetSwipeDirection()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeDirection])

  type Direction = 'next' | 'prev'
  const navigateContent = (direction: Direction) => {
    let newIndex
    if (direction === 'next') {
      newIndex = (fullScreenIndex + 1) % fullScreenChannel.length
    } else {
      newIndex =
        fullScreenIndex - 1 < 0
          ? fullScreenChannel.length - 1
          : fullScreenIndex - 1
    }

    if (newIndex === 1) {
      navigate(`/meet-the-cast/313c3231-70e4-4c09-8986-32ef6f3bc3af?feed=true`)
    }

    setFullScreenIndex(newIndex)
    setCurrentEvent(fullScreenChannel[newIndex])
  }

  const handlePlay = useCallback(() => {
    setIsMuted(true)
    if (videoRef.current) {
      // Attempt to play the video
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true)
        })
        .catch(() => {
          setIsPlaying(false)
        })
    }
  }, [])

  const handleSkipContent = (next?: boolean) => {
    next ? navigateContent('next') : navigateContent('prev')
    setCurrentTime(0)
  }

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration)
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: contentLoaded ? 1 : 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={`relative m-0 w-full h-full flex flex-col justify-center bg-[black] ${!contentLoaded && 'hidden'}`}
      >
        <motion.span
          onClick={() => {
            navigate(`/tickets`)
          }}
          className="fixed cursor-pointer top-0 right-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
        >
          <HouseIcon />
        </motion.span>

        {isExclusive && (
          <div className="w-full h-auto z-50 flex justify-center">
            <span className="absolute top-0 m-4 bg-yellow text-purple text-sm rounded-full py-[3px] px-3">{`Exclusive`}</span>
          </div>
        )}

        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => {
            setFullScreenIndex(0)
            navigate(`/channel/313c3231-70e4-4c09-8986-32ef6f3bc3af`)
          }}
          className="fixed cursor-pointer top-0 left-0 m-4 z-50 bg-purple text-white rounded-full w-8 h-8 flex items-center justify-center"
        >
          <Arrow />
        </motion.span>

        <div className="relative w-full h-full">
          {!isPlaying && contentLoaded && (
            <div className="absolute top-0 w-[100vw] h-full flex items-center justify-center">
              <div
                className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
                onClick={handlePlay}
              >
                <PlayIcon />
              </div>
            </div>
          )}

          <video
            key={currentEvent?.content_url}
            ref={videoRef}
            controls={false}
            autoPlay={isPlaying}
            loop
            muted={isMuted}
            playsInline={true}
            className={`absolute top-0 w-full h-[100svh] object-cover`}
            onLoadedMetadata={handleLoadedMetadata}
            onTimeUpdate={handleTimeUpdate}
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <source src={currentEvent?.content_url} />
          </video>

          <div className="absolute inset-0 z-20 pointer-events-none" />

          <div className="w-full items-center justify-between absolute py-4 p-4 bottom-0 flex bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]">
            <div className="flex flex-col w-full">
              <div className="flex gap-2 items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <img
                    src={mainContent.badge_url}
                    alt="preshowOne"
                    className="w-8 h-8 rounded-[4px]"
                  />
                  <p className="font-bold">{mainContent.name}</p>
                </div>

                {isPlaying && (
                  <div className="relative">
                    <div
                      className="cursor-pointer top-0 z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
                      onClick={() => setIsMuted(!isMuted)}
                    >
                      {isMuted ? <VolumeOff /> : <VolumeOn />}
                    </div>
                  </div>
                )}
              </div>

              {currentEvent?.description?.length && (
                <div className="pt-3">
                  <ExpandableText
                    text={currentEvent?.description as string}
                    limit={150}
                  />
                </div>
              )}

              {/* Timeline (Seek Bar) */}
              {contentLoaded && (
                <div className="relative flex flex-col items-center bg-transparent py-4">
                  <SeekBar
                    currentIndex={0}
                    videoRef={videoRef}
                    duration={duration}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    isActive
                  />

                  {/* Custom styles to hide the default thumb */}
                  <style>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 0;
                height: 0;
              }
              input[type='range']::-moz-range-thumb {
                width: 0;
                height: 0;
              }
            `}</style>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
